import { Component } from "react";
import Splash from "./Splash";

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      overlay: false,
      user: false,
      showSplash: true
    }
    this.login = this.login.bind(this)
    this.hideSplash = this.hideSplash.bind(this)
    this.showSplash = this.showSplash.bind(this)
  }

  async _checkAcc() {
    const res = await fetch("https://ileri-production.glitch.me/users")
    const body = await res.json()
    if(localStorage.getItem("loggedIn") && body.filter(usr => usr.id === localStorage.getItem("id")).length > 0) {
      this.props.route("home")
    } else {
      this.hideSplash()
    }
  }

  componentDidMount() {
    this._checkAcc();

    document.getElementById("nameInput").addEventListener("keypress", (e) => {
      if(e.key === "Enter") this.login()
    })
  }

  async login() {
    if(!this.state.name) return alert("Enter a name!")
    if(this.state.name === "debug") return alert(`ID: ${localStorage.getItem("id")}
    isLoggedIn: ${localStorage.getItem("isLoggedIn")}`)
    this.showSplash()
    const res = await fetch("https://ileri-production.glitch.me/users")
    const body = await res.json()
    const user = body.find(user => user.name.split(' ').join('').toLowerCase() === this.state.name.split(' ').join('').toLowerCase())
    if(user) {
      localStorage.setItem("loggedIn", true)
      localStorage.setItem("id", user.id)
      this.setState({ user: true })
      this.props.route("home")
    } else {
      this.hideSplash()
      this.setState({ name: "", user: false })
      alert("There is no such user!")
    }
  }

  hideSplash() {
    this.setState({ showSplash: false })
  }

  showSplash() {
    this.setState({ showSplash: true })
  }
  
  render() {    
    return(
      <div className="container">
        <Splash show={this.state.showSplash} />
        <span className="header">Login</span>
        <div className="card">
          <input autoComplete="off" id="nameInput" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} placeholder="Name" className="nameInput"></input>
          <button onClick={this.login} className="button">
            <span className="buttonText">Let me in</span>
          </button>
        </div>
        <style>{`
          .container {
            display: flex;
            flex: 1;
            background-color: #045174;
            align-items: center;
            justify-content: center;
            position: relative;
          }

          .header {
            font-size: 30px;
            color: #fff;
            position: absolute;
            top: 20%;
            font-weight: 700;
          }

          .card {
            width: 270px;
            height: 205px;
            background-color: #B9D6F2;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
          }

          .nameInput {
            background-color: rgba(0, 0, 0, 0.1);
            width: 195px;
            height: 30px;
            border-radius: 10px;
            padding: 10px;
            font-weight: 700;
            margin-bottom: 30px;
            color: #000;
            border: none;
            outline: none;
          }

          .button {
            width: 100%;
            height: 50px;
            cursor: pointer;
            border-color: rgba(0, 0, 0, 0.1);
            background: rgba(0, 0, 0, 0);
            border-width: 0.5px 0px 0px;
            border-radius: 20px;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .button:hover .buttonText {
            color: rgba(0, 0, 0, 0.3);
          }

          .button:active .buttonText {
            color: #94abc2;
          }

          .buttonText {
            color: #6f8091;
            font-weight: 700;
            transition: 0.2s;
          }
        `}</style>
      </div>
    )
  }
}