import { Component } from 'react';
import Home from './Pages/Home';
import Login from './Pages/Login';
import ContentInfo from './Pages/ContentInfo';
import Splash from './Pages/Splash';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: "splash"
    }
    this.route = this.route.bind(this)
  }

  route(page, id) {
    this.setState({ page })
    if(page === "login") return window.history.pushState({}, "", `/`)
    if(id) return window.history.pushState({}, "", `/${page}/${id}`)
    window.history.pushState({}, "", `/${page}`)
  }

  componentDidMount() {
    const pathnames = window.location.pathname.split("/")
    if(pathnames[1] && pathnames[2]) this.route(pathnames[1], pathnames[2])
    else if(pathnames[1]) this.route(pathnames[1])
    else this.route("login")

    window.addEventListener("popstate", (e) => {
      document.location.reload()
    })
  }

  render() {
    switch(this.state.page) {
      case "home":
        return <Home route={this.route} />
      case "login":
        return <Login route={this.route} />
      case "content":
        return <ContentInfo route={this.route} />
      case "splash":
        return <Splash/>
      default:
        return <Splash/>
    }
  }
}

export default App;
