import { Component } from "react";
import Splash from "./Splash";

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      refreshing: false,
      logout: false,
      showSplash: true
    }
    this.refresh = this.refresh.bind(this)
    this.hideSplash = this.hideSplash.bind(this)
    this.showSplash = this.showSplash.bind(this)
  }

  refresh() {
    this.setState({ refreshing: true })
    fetch(`https://ileri-production.glitch.me/users/${localStorage.getItem("id")}/contents`).then(res => res.json()).then(data => {
      this.setState({ data: data, refreshing: false })
    })
  }

  componentDidMount() {
    if(!localStorage.getItem("loggedIn")) this.props.route("login")
    fetch(`https://ileri-production.glitch.me/users/${localStorage.getItem("id")}/contents`).then(res => res.json()).then(data => {
      this.setState({ data: data })
      setTimeout(() => {
        this.hideSplash()
      }, 300)
    })
  }

  hideSplash() {
    this.setState({ showSplash: false })
  }

  showSplash() {
    this.setState({ showSplash: true })
  }

  render() {
    function Content({ item, month, route }) {
      return(
        <div className="content" onClick={() => route("content", item.id)}>
          <span style={{ fontWeight: "700", fontSize: 17, color: "#000" }}>{item.name + " (" + item.type + ")"}</span>
          <span style={{ fontWeight: "500", color: "#000", fontSize: 15 }}>{item.date + " " + item.month}</span>
          <style>{`
            .content {
              display: flex;
              flex-direction: column;
              background-color: #94abc2;
              padding: 25px;
              transition: .2s;
              cursor: pointer;
              user-select: none;
            }

            .content:hover {
              opacity: .8;
            }
          `}</style>
        </div>
      )
    }
    
    return(
      <div className="container">
        <Splash show={this.state.showSplash} />
        <div style={{ top: "10%", flexDirection: "row", position: "absolute" }}>
          {this.state.data ? <span className="header">{this.state.data.month}</span> : <div style={{ width: 120, height: 40, borderRadius: 15, backgroundColor: "rgba(0, 0, 0, 0.4)" }}></div>}
          <span className="header"> Schedule</span>
        </div>
        <div className="schedule">
          {this.state.data ? this.state.data.schedule.length > 0 ? this.state.data.schedule.map((item, i) => (
            <Content key={i} route={this.props.route} item={item} month={this.state.data.month} />
          )) : <span className="noContentWarning">There is no content to show!</span> : <span className="noContentWarning">There is no content to show!</span>}
        </div>
        <div style={{ position: "absolute", bottom: 50, display: "flex", flexDirection: "column" }}>
          <button onClick={() => {
            const question = window.confirm("Do you want to leave? If you press OK you will be logged out.")
            if(question) {
              localStorage.removeItem("id")
              localStorage.removeItem("loggedIn")
              this.props.route("login")
            }
          }} className="logOutButton">
            Log Out
          </button>
          <span style={{ fontWeight: "700", fontSize: 11, userSelect: "none", textAlign: "center", paddingTop: 10, color: "#94abc2" }}>v2.0.1</span>
        </div>
        <style>{`
          .container {
            width: 100%;
            height: 100%;
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            background-color: #045174;
            position: relative;
          }

          .header {
            font-weight: 700;
            font-size: 30px;
            color: #fff;
          }

          .schedule {
            width: 100%;
            height: 80%;
            bottom: 0;
            position: absolute;
          }

          .logOutButton {
            border-radius: 20px;
            background-color: #FFCFD2;
            border: none;
            padding: 10px;
            color: #23272a;
            font-weight: 700;
            cursor: pointer;
            transition: .2s;
          }

          .logOutButton:hover {
            opacity: .7;
          }

          .logOutButton:active {
            opacity: .6;
          }

          .noContentWarning {
            font-size: 20px;
            font-weight: 700;
            opacity: .7;
            color: #fff;
            text-align: center;
            position: absolute;
            left: 50%;
            top: 20%;
            transform: translate(-50%);
          }
        `}</style>
      </div>
    )
  }
}