import { Component } from "react";

export default class Splash extends Component {
  render() {
    return(
      <div style={this.props.show ? { opacity: 1, zIndex: 999 } : { opacity: 0, zIndex: -999 }} className="splash">
        <img draggable="false" alt="Ileri Production Logo" width={200} height={200} src="/logo.png"></img>
        <style>{`
          .splash {
            width: 100%;
            height: 100%;
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #045174;
            transition: .4s;
            user-select: none;
          }
        `}</style>
      </div>
    )
  }
}