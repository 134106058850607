import { Component } from "react";
import Splash from "./Splash";

export default class ContentInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      link: null,
      content: { name: "", type: "", date: "", by: "", id: "", month: "" },
      showSplash: true
    }
    this.save = this.save.bind(this)
  }

  componentDidMount() {
    fetch(`https://ileri-production.glitch.me/contents/${window.location.pathname.split("/")[2]}`).then(res => res.json()).then(data => {
      if(data.link !== "Link not found." && data.link) this.setState({ link: data.link })

      this.setState({ content: data }, () => this.hideSplash())
    }).catch(() => {
      this.props.route("home")
    })
  }

  save() {
    const link = { link: this.state.link }
    fetch("https://ileri-production.glitch.me/contents/" + this.state.content.id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(link)
    }).then(() => {
      alert("Link saved")
    })
  }

  hideSplash() {
    this.setState({ showSplash: false })
  }

  showSplash() {
    this.setState({ showSplash: true })
  }

  render() {
    return(
      <div className="container">
        <Splash show={this.state.showSplash} />
        <span className="header">{this.state.content.name}</span>
        <div className="form">
          <textarea autoCorrect={false} value={this.state.link} onChange={e => this.setState({ link: e.target.value })} rows={1} className="input" placeholder="Paste Canva link"></textarea>
          <button onClick={this.save} disabled={this.state.link ? false : true} className="button">
            <span className="buttonText">Save</span>
          </button>
        </div>
        <style>{`
          .container {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #045174;
          }

          .header {
            font-weight: 700;
            position: absolute;
            top: 10%;
            font-size: 30px;
            color: #fff;
            text-align: center;
          }

          .form {
            display: flex;
            flex-direction: column;
          }

          .input {
            background-color: #B9D6F2;
            width: 260px;
            border-radius: 10px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            padding: 15px 10px;
            font-weight: 700;
            color: #000;
            border: none;
            resize: none;
          }

          .button {
            display: flex;
            justify-content: center;
            align-items: center;
            border-color: rgba(0, 0, 0, 0.1);
            background-color: #B9D6F2;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            border-width: 0.5px 0 0 0;
            cursor: pointer;
          }

          .button:hover .buttonText {
            color: rgba(0, 0, 0, 0.3);
          }

          .button:active .buttonText {
            color: #94abc2;
          }

          .buttonText {
            color: #6f8091;
            font-weight: 700;
            padding-top: 10px;
            padding-right: 20px;
            padding-bottom: 10px;
            padding-left: 20px;
            transition: .2s;
          }
        `}
        </style>
      </div>
    )
  }
}